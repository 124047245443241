import { useState } from 'react';
import { FaSpinner } from 'react-icons/fa';

function Button(props) {
  const { colorpicker, onClick, content, disabled, isLoading, active } = props;

  const bgColors = {
    disabled: "opacity-90",
    confirm: "bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-[auto] focus:ring-green-300",
    blue: " bg-blue-500 hover:bg-blue-600 text-white mb-2 font-bold py-2 px-4 rounded focus:ring-blue-800" + (active ? " bg-blue-800" : ""),
    btn: "text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br 0 dark:fofocus:ring-4 focus:outline-none focus:ring-teal-30cus:ring-teal-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2",
    payment: "text-white bg-lime-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-lime-300 dark:focus:ring-lime-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-block mb-4 ml-4 mt-1.5"
  };
 
  const handleClick = () => {
    if (typeof onClick === "function") 
    onClick();


  }
  

  return (
<button
  className={bgColors[colorpicker]}
  onClick={handleClick}
  disabled={disabled || isLoading}
>
  {isLoading ? (
    <span className="flex items-center">
      <FaSpinner className="animate-spin mr-2" />
      Loading
    </span>
  ) : (
    content
  )}
</button>

  );
}

export default Button;
