import { Link } from "react-router-dom";

const PaymentFailed = () => {
  return (
    <section className="flex items-center h-full p-16 bg-white-900 text-black-100 absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
      <div className="container flex flex-col items-center justify-center px-5 mx-auto my-8">
        <div className="max-w-md text-center">
          <h2 className="mb-8 font-extrabold text-4xl text-black-600">
            <span className="sr-only">Error</span>Payment Failed!
          </h2>
          <p className="text-2xl font-semibold md:text-3xl">
            Your payment did not go through!
          </p>
          <p className="mt-4 mb-8 text-black-400">
            Please click on the below link to visit payment page.
          </p>
          <Link to="/">
            <span style={{cursor:"pointer"}}
              rel="noopener noreferrer"
              className="px-8 py-3 font-semibold rounded bg-violet-400 text-gray-900"
            >
              Payment Page
            </span>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default PaymentFailed;
