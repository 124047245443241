import { ToastContainer } from 'react-toastify';
import Logo from 'images/logo.png'
import 'react-toastify/dist/ReactToastify.css';

const Header = () => {
  return (
    <header className="border-y bg-white sticky top-0 z-10">
      <ToastContainer style={{ zIndex: 20 }} position="top-right" theme="colored" autoClose={3000} />
      <nav className="w-[90%] max-w-5xl mx-4 py-4">
        <img
          className="max-w-[120px]"
          src={Logo}
          alt="nvidia logo"
        />
      </nav>
    </header>
  );
};

export default Header;
