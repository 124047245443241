import React from "react";
import "../../../src/index.css";
import 'moment-timezone';

const Reschedule = () => {
  
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60vh' }}>
        <div className="text-center">
          <h1>
            To reschedule your exam slots, please contact <span className="text-blue-500">academy-support@nvidia.com</span>
          </h1>
        </div>
      </div>
    </>
  );
};

export default Reschedule;
