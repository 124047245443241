import { faWallet, faClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Logo from 'images/logo.png';
import { useContext, useEffect, useState } from "react";
import { AuthContext } from '../../containers/Store/Index';

const ExamDetails = ({ userSession, discountedCost, setDiscountedCost }) => {
  const { title, time, cost } = userSession;
  const [displayCost, setDisplayCost] = useState(cost);

  useEffect(() => {
    if (discountedCost !== undefined) {
      setDisplayCost(discountedCost);
    } else {
      setDisplayCost(cost);
    }
  }, [cost, discountedCost]);

  return (
    <div>
      <div className="p-10 border-gray-100 border-b-2">
        <img
          src={Logo}
          alt="Nvidia Logo"
        />
      </div>
      <div className="px-5 pt-5">
        <h1 className="text-2xl font-bold">{title}</h1>
        <div className="pt-6 text-zinc-500 flex">
          <div>
            <p>
              <FontAwesomeIcon icon={faClock} className="pr-1" /> {time}
            </p>
            <p>
              <FontAwesomeIcon icon={faWallet} className="pr-2" />
              ${displayCost}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExamDetails;
