const Footer = () => {
  return (
    <footer className="bg-slate-800 py-5 fixed w-full bottom-0">
      <div className="text-white w-[90%] max-w-5xl mx-4">
        <div>
          <p>&copy; 2022 Talview Inc | All Rights Reserved</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
