const SchduleSuccess = () => {
  localStorage.clear();

  return (
    <div className="container ">
      <div className="flex flex-col items-center justify-center w-screen pt-24">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="text-green-600 w-28 h-28"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth="1"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
        <p className="text-2xl font-semibold md:text-2xl p-3 pb-8">
        Thank you for scheduling your exam. You will receive an email with the exam details.
          </p>
        <p className="text-2xl md:text-xl mb-8 mx-8">
            If you do not receive the email within 15 minutes from "no-reply@talview.com" (check spam and junk folders), please <a target="_blank" rel="noreferrer" className="text-lime-600" href="https://talview.freshdesk.com/support/tickets/new"> Contact Support.</a> 
          </p>
          <p className="text-2xl md:text-xl mb-8">
            You may close this window.
          </p>
      </div>
    </div>
  );
};

export default SchduleSuccess;
