import { Route, Routes } from "react-router-dom";
import Header from "./components/Header/Index";
import Footer from "./components/Footer/Index";
import Main from "./containers/App/Index";
import Schedule from "./containers/Schedule";
import Success from "./components/PaymentSuccess/Index";
import Failed from "./components/PaymentFailed/Index";
import Later from "./components/SchduleLater/Index";
import SchduleSuccess from "./components/SchduleSuccess/Index";
import Reschedule from "containers/reschedule/index";
import "./index.css";

function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/schedule" element={<Schedule />} />
        <Route path="/success" element={<Success />} />
        <Route path="/fail" element={<Failed />} />
        <Route path="/scheduled" element={<SchduleSuccess />} />
        <Route path="/later" element={<Later />} />
        <Route path="/reschedule" element={<Reschedule />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
