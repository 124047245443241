const SchduleLater = () => {

  localStorage.clear();

  return (
    <section className=" w-[90%] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-100%] rounded-2xl flex flex-col shadow-lg border-gray-100 border-[1px] mx-auto mt-16">
      <div>
        <div className="text-center">
          <h2 className="mb-8 font-semibold text-2xl text-black-600 mt-4">
            You have selected to schedule your exam later.
          </h2>
          <p className="text-2xl font-semibold md:text-2xl mb-8">
            You will receive an email with the link to schedule your exam.
          </p>
          <p className="text-2xl md:text-xl mb-8">
            If you do not receive the email within 15 minutes from "no-reply@talview.com" (check spam and junk folders), please contact us: <a className="text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2" href="https://talview.freshdesk.com/support/tickets/new"
            target="_blank" rel="noreferrer"> Contact Support</a>
          </p>
          <p className="text-2xl md:text-xl mb-8">
            You may close this window
          </p>

        </div>
      </div>
    </section>
  );
};

export default SchduleLater