import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Button from "../Button/Index";
import { scheduleLater } from "../../utils/index";


const PaymentSuccess = () => {
  const navigate = useNavigate();
  const handleSchedule = () => {
    navigate(`/schedule`);
  };

  const handleScheduleLater = () => {
    scheduleLater()
      .then(data => {
        toast.success(`Email delivered!`);
        navigate(`/later`);
      })
      .catch(e => {
        toast.error('Something went wrong: ' + e.message);
      });
  };
  

  return (
    <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-65%]">
      <div className="flex flex-col items-center justify-center w-screen">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="text-green-600 w-28 h-28"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth="1"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
        <h1 className="text-4xl font-bold">Thank you for your purchase</h1>
        <p className="p-3">Please schedule the exam accordingly.</p>
        <div className="flex">
          <Button
            colorpicker="btn"
            onClick={() => handleSchedule()}
            content={"Schedule Now"}
          />
          <Button colorpicker="btn" content={"Schedule Later"}
          onClick={() => {
             handleScheduleLater();
             }}
           />
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccess;
