import { useState, useContext } from "react";
import { AuthContext } from "../Store/Index";
import ExamDetails from "../../components/ExamDetails/Index";
import Pay from "../Payment/Index";

const Main = () => {
  const userSession = useContext(AuthContext);

  const [discountedCost, setDiscountedCost] = useState(userSession.cost);

  return (
    <div className="max-w-md w-[90%] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] rounded-2xl flex flex-col shadow-lg border-gray-100 border-[1px]">
      {userSession && (
        <>
          <ExamDetails userSession={userSession} discountedCost={discountedCost} />
          <Pay setDiscountedCost={setDiscountedCost} />
        </>
      )}
    </div>
  );
};
export default Main;
