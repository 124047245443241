import React, { createContext, useEffect, useState } from "react";

export const AuthContext = createContext({});

export const AuthContextProvider = (props) => {
  const [userSession, setUserSession] = useState({});

  const updateUserSession = (params, storedUserSession) => {
    storedUserSession.token = params.get("c__token");
    storedUserSession.title = params.get("title") || storedUserSession.title;
    storedUserSession.cost = params.get("cost") || storedUserSession.cost;
    storedUserSession.time = params.get("time") || storedUserSession.time;
    return storedUserSession;
  };

  const addCouponToUserSession = (couponCode, paymentStatus) => {
    const storedUserSession = JSON.parse(localStorage.getItem("nv-user-session")) || {};
    storedUserSession.coupon = couponCode;
    storedUserSession.payment_status = paymentStatus;
    localStorage.setItem("nv-user-session", JSON.stringify(storedUserSession));
    setUserSession(storedUserSession);
  };

  const clearCouponFromUserSession = () => {
    const storedUserSession = JSON.parse(localStorage.getItem("nv-user-session")) || {};
    delete storedUserSession.coupon;
    delete storedUserSession.payment_status;
    localStorage.setItem("nv-user-session", JSON.stringify(storedUserSession));
    setUserSession(storedUserSession);
  };

  const updateCost = (newCost) => {
    const storedUserSession = JSON.parse(localStorage.getItem("nv-user-session")) || {};
    storedUserSession.cost = newCost;
    localStorage.setItem("nv-user-session", JSON.stringify(storedUserSession));
    setUserSession(storedUserSession);
  };
  
  // This useEffect hook updates the user session from the URL parameters or localStorage.
  useEffect(() => {
    const params = new URL(document.location).searchParams;
    const storedUserSession = JSON.parse(localStorage.getItem("nv-user-session")) || {};
    if (params.get("c__token") && storedUserSession?.token !== params.get("c__token")) {
      const updatedUserSession = updateUserSession(params, storedUserSession);
      localStorage.setItem("nv-user-session", JSON.stringify(updatedUserSession));
      setUserSession(updatedUserSession);
    } else {
      setUserSession(storedUserSession);
    }
  }, []);

  return (
    <AuthContext.Provider value={{ ...userSession, addCouponToUserSession, clearCouponFromUserSession, updateCost }}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
